"use client"

import React from "react"
import { usePathname, useSearchParams } from "next/navigation"

import { Dialog, DialogContent } from "@/components/ui/dialog"

import { SigninCard } from "./signin-card"

type SigninDialogProps = {
  open: boolean
  onClose: () => void
}

export function SigninDialog({ open, onClose }: SigninDialogProps) {
  const searchParams = useSearchParams()
  const pathname = usePathname()

  const createQueryString = React.useCallback(
    (params: Record<string, string | number | null>) => {
      const newSearchParams = new URLSearchParams(searchParams?.toString())

      for (const [key, value] of Object.entries(params)) {
        if (value === null) {
          newSearchParams.delete(key)
        } else {
          newSearchParams.set(key, String(value))
        }
      }
      newSearchParams.sort()
      return newSearchParams.toString()
    },
    [searchParams]
  )

  React.useEffect(() => {
    if (open)
      window.history.replaceState(
        {},
        "",
        `?${createQueryString({ redirect_url: `${pathname}?${searchParams}` })}`
      )

    return () => window.history.replaceState({}, "", `?${searchParams}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog open={open} onOpenChange={onClose} modal>
      <DialogContent>
        <SigninCard shouldShowSinUpOption onRedirect={onClose} />
      </DialogContent>
    </Dialog>
  )
}
