import React from "react"
import { MembershipPlan } from "@/types"
import { useRouter } from "next-nprogress-bar"

import { Dialog, DialogContent } from "@/components/ui/dialog"
import { Icons } from "@/components/icons"
import { MembershipPlans } from "@/components/membership-plans/membership-plans"
import { createNewPropertyAction } from "@/app/_actions/properties"
import { updateUserMembershipPlanAction } from "@/app/_actions/users"

type MembershipPlansDialogProps = {
  open: boolean
  onClose: () => void
}

export function MembershipPlansDialog({
  open,
  onClose,
}: MembershipPlansDialogProps) {
  const router = useRouter()
  const [isPending, startTransition] = React.useTransition()

  const handleSelectMembershipPlan = async (membershipPlan: MembershipPlan) => {
    startTransition(async () => {
      await updateUserMembershipPlanAction(membershipPlan)

      const propertyId = await createNewPropertyAction()

      if (propertyId)
        router.push(`/publicar-anuncio/${propertyId}/operacion-y-propiedad`)

      onClose()
    })
  }

  return (
    <Dialog open={open} onOpenChange={onClose} modal>
      <DialogContent
        shouldCloseOnOverlayClick={false}
        showCloseButton={false}
        className="max-h-dvh !max-w-5xl overflow-y-auto"
      >
        {isPending ? (
          <div
            role="status"
            aria-label="Loading"
            aria-describedby="loading-description"
            className="flex items-center justify-center"
          >
            <Icons.spinner
              className="size-16 animate-spin"
              aria-hidden="true"
            />
          </div>
        ) : (
          <MembershipPlans
            action="update-user-membership-plan"
            onMembershipPlanSelect={handleSelectMembershipPlan}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
