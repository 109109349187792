"use client"

import Link from "next/link"
import { SignUpButton } from "@clerk/nextjs"

import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import { OAuthProviders } from "@/modules/auth/components/oauth-providers"
import { SignInForm } from "@/modules/auth/forms/signin-form"

type SigninCardProps = {
  shouldShowSinUpOption?: boolean
  onRedirect?: () => void
}
export function SigninCard({
  shouldShowSinUpOption = false,
  onRedirect,
}: SigninCardProps) {
  return (
    <Card>
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl">Inicia sesión</CardTitle>
        <CardDescription>
          Elige una de las siguientes opciones para iniciar sesión.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4">
        <OAuthProviders mode="signin" />
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-t" />
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-background px-2 text-muted-foreground">
              O continua con
            </span>
          </div>
        </div>
        <SignInForm />
      </CardContent>
      <CardFooter>
        <div className="flex w-full flex-col gap-5">
          <Link
            aria-label="Reset password"
            href="/ingreso/restablecer-contrasena"
            className="text-center text-sm text-primary underline-offset-4 transition-colors hover:underline"
            onClick={() => onRedirect && onRedirect()}
          >
            ¿Has olvidado tu contraseña?
          </Link>
          {shouldShowSinUpOption && (
            <>
              <Separator />
              <div className="flex items-center justify-center gap-2">
                <div className="text-center">¿Eres nuevo en wasii.pe?</div>
                <SignUpButton>
                  <Button
                    variant="link"
                    className="px-0"
                    onClick={() => onRedirect && onRedirect()}
                  >
                    Regístrate
                  </Button>
                </SignUpButton>
              </div>
            </>
          )}
        </div>
      </CardFooter>
    </Card>
  )
}
