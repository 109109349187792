"use client"

import Image from "next/image"
import Link from "next/link"
import { MembershipPlan } from "@/types"

import { cn } from "@/lib/utils"
import { Button, buttonVariants } from "@/components/ui/button"

export type MembershipPlanItemProps = {
  action: "redirect" | "update-user-membership-plan" | "none"
  membershipPlan: MembershipPlan
  title: string
  description: string
  perks: { name: string; children?: string[] }[]
  cta: string
  comingSoon?: boolean
  icon?: { src: string; size: number }
  className?: string
  onMembershipPlanSelect?: (membershipPlan: MembershipPlan) => void
}
export function MembershipPlanItem({
  action,
  membershipPlan,
  title,
  description,
  perks,
  cta,
  comingSoon = false,
  icon,
  className,
  onMembershipPlanSelect,
}: MembershipPlanItemProps) {
  const getRedirectUrl = () => {
    switch (membershipPlan) {
      case "individual":
        return "/registro/particular"
      case "professional":
        return "/registro/profesional"
      case "enterprise":
        return "/registro/empresarial"
      default:
        return "/registro"
    }
  }

  return (
    <div
      className={cn(
        "flex min-h-[345px] w-[280px] min-w-0 max-w-[280px] flex-col rounded-2xl border border-border p-6 lg:w-auto lg:max-w-none lg:basis-1/3",
        className
      )}
    >
      <div className="flex items-center justify-between pb-4 text-xl font-medium">
        <div>{title}</div>
        {icon && (
          <Image
            src={icon.src}
            alt={title}
            width={icon.size}
            height={icon.size}
          />
        )}
      </div>
      <div className="h-12 text-base">
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </div>
      <div className={cn(comingSoon && "my-5 blur-sm", "mb-14 mt-10 flex-1")}>
        <ul className="ml-6 list-disc space-y-2">
          {perks.map((perk, index) => (
            <li key={index}>
              <div className="mb-2">{perk.name}</div>
              {perk.children && (
                <ul className="ml-6 list-disc space-y-2">
                  {perk.children.map((child, index) => (
                    <li key={index}>{child}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-auto">
        {action === "redirect" ? (
          <Link
            href={getRedirectUrl()}
            className={cn(
              buttonVariants({
                variant: comingSoon ? "outline" : "secondary",
                size: "lg",
              }),
              comingSoon && "pointer-events-none cursor-not-allowed",
              "w-full px-0 text-lg"
            )}
          >
            {cta}
          </Link>
        ) : action === "update-user-membership-plan" ? (
          <Button
            variant={comingSoon ? "outline" : "secondary"}
            size="lg"
            className={cn(
              comingSoon && "cursor-not-allowed",
              "w-full px-0 text-lg"
            )}
            onClick={() =>
              onMembershipPlanSelect && onMembershipPlanSelect(membershipPlan)
            }
          >
            {cta}
          </Button>
        ) : null}
      </div>
    </div>
  )
}
