"use client"

import { MembershipPlan } from "@/types"

import { membershipConfig } from "@/config/membership-config"
import { cn } from "@/lib/utils"
import { MembershipPlanItem } from "@/components/membership-plans/membership-plan-item"

type MembershipPlansProps = {
  action: "redirect" | "update-user-membership-plan"
  onMembershipPlanSelect?: (membershipPlan: MembershipPlan) => void
}
export function MembershipPlans({
  action,
  onMembershipPlanSelect,
}: MembershipPlansProps) {
  return (
    <div>
      <h2 className="text-balance text-center text-3xl font-medium lg:text-left">
        Elige tu plan
      </h2>
      <div className="flex flex-col items-center justify-stretch gap-4 pt-10 lg:flex-row lg:items-stretch">
        {membershipConfig.map((plan) => (
          <MembershipPlanItem
            {...plan}
            key={plan.membershipPlan}
            action={action}
            onMembershipPlanSelect={() =>
              onMembershipPlanSelect
                ? onMembershipPlanSelect(plan.membershipPlan)
                : undefined
            }
            icon={plan.icon}
            className={cn(
              (plan.membershipPlan === "professional" ||
                plan.membershipPlan === "enterprise") &&
                "border-black",
              plan.membershipPlan === "enterprise" && "bg-[#681EB004]"
            )}
          />
        ))}
      </div>
    </div>
  )
}
