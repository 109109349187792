"use client"

import { createContext, useCallback, useContext, useState } from "react"

import { MembershipPlansDialog } from "@/modules/auth/components/membership-plans-dialog"
import { SigninDialog } from "@/modules/auth/components/signin-dialog"

interface ModalProviderProps {
  children: React.ReactNode
}

export enum Modal {
  Signin,
  MembershipPlans,
}

export interface ModalContext {
  openModal(modal?: Modal): void
}

export const ModalContext = createContext<ModalContext>({
  openModal() {},
})

export function ModalProvider({ children }: ModalProviderProps): JSX.Element {
  const [currentModal, setCurrentModal] = useState<Modal>()

  const openModal = useCallback(
    (modal: Modal | undefined): void => {
      setCurrentModal(modal)
    },
    [setCurrentModal]
  )

  const closeModal = useCallback((): void => {
    setCurrentModal(undefined)
  }, [])

  return (
    <ModalContext.Provider
      value={{
        openModal,
      }}
    >
      {children}
      <SigninDialog open={currentModal === Modal.Signin} onClose={closeModal} />
      <MembershipPlansDialog
        open={currentModal === Modal.MembershipPlans}
        onClose={closeModal}
      />
    </ModalContext.Provider>
  )
}

export function useModalContext(): ModalContext {
  return useContext(ModalContext)
}
