"use client"

import * as React from "react"
import { useModalContext } from "@/context/modal-context"
import { useSignIn } from "@clerk/nextjs"
import { zodResolver } from "@hookform/resolvers/zod"
import { useRouter } from "next-nprogress-bar"
import { useForm } from "react-hook-form"
import type { z } from "zod"

import { catchClerkError } from "@/lib/utils"
import { useHashSearchParams } from "@/hooks/use-hash-search-params"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Icons } from "@/components/icons"
import { PasswordInput } from "@/components/password-input"
import { signInSchema } from "@/modules/auth/validations/auth"

import { LastProviderBadge } from "../components/last-provider-badge"

type Inputs = z.infer<typeof signInSchema>

export function SignInForm() {
  const router = useRouter()
  const hashSearchParams = useHashSearchParams()
  const redirectUrl = hashSearchParams.get("redirect_url")
  const { isLoaded, signIn, setActive } = useSignIn()
  const [isPending, startTransition] = React.useTransition()
  const { openModal } = useModalContext()

  // react-hook-form
  const form = useForm<Inputs>({
    resolver: zodResolver(signInSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  function onSubmit(data: Inputs) {
    if (!isLoaded) return

    startTransition(async () => {
      try {
        localStorage.setItem("loginAttempProvider", "email")

        const result = await signIn.create({
          identifier: data.email,
          password: data.password,
        })

        if (result.status === "complete") {
          await setActive({ session: result.createdSessionId })
          openModal(undefined)
          if (window.location.pathname === "/ingreso")
            router.push(redirectUrl ?? `${window.location.origin}/`)
        } else {
          /*Investigate why the login hasn't completed */
          console.log(result)
        }
      } catch (err) {
        catchClerkError(err)
      }
    })
  }

  return (
    <Form {...form}>
      <form
        className="grid gap-4 pt-5"
        onSubmit={(...args) => void form.handleSubmit(onSubmit)(...args)}
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="relative flex w-full items-center justify-between">
                Correo electrónico
                {localStorage.getItem("lastLoginProvider") === "email" && (
                  <LastProviderBadge className="absolute -top-6 left-0 sm:relative sm:top-0" />
                )}
              </FormLabel>
              <FormControl>
                <Input placeholder="Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contraseña</FormLabel>
              <FormControl>
                <PasswordInput placeholder="**********" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button disabled={isPending}>
          {isPending && (
            <Icons.spinner
              className="mr-2 size-4 animate-spin"
              aria-hidden="true"
            />
          )}
          Iniciar sesión
          <span className="sr-only">Iniciar sesión</span>
        </Button>
      </form>
    </Form>
  )
}
